import $ from 'jquery';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

$(function() {
  $('.carousel-paper').slick({
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 2.5,
    slidesToScroll: 1,
    prevArrow: $('.prev-papers'),
    nextArrow: $('.next-papers'),
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.carousel-paper').show();
});

const paperFiles = document.querySelectorAll('.file')
const paperUrls = document.querySelectorAll('.paper-url')
const paperBtn = document.querySelectorAll('.paper-copy')

function copyUrlToClipboard(i) {
  let inputc = document.body.appendChild(document.createElement("input"));
  inputc.value = paperUrls[i].getAttribute('href');
  inputc.focus({
    preventScroll: true
  });
  inputc.select();
  document.execCommand('copy');
  inputc.parentNode.removeChild(inputc);
}

for (let i = 0; i < paperFiles.length; i++) {
  if (paperBtn[i]) {
    paperBtn[i].addEventListener('click', () => {
      copyUrlToClipboard(i)
    })
  }
}