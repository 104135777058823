/* Show next and prev buttons */
const heroPrev = document.querySelector('.hero-navprev')
const heroNext = document.querySelector('.hero-navnext')

const prevButton = document.querySelector('.prev-hero')
const nextButton = document.querySelector('.next-hero')

if(heroPrev) {
  heroPrev.addEventListener('mouseenter', () => {
    prevButton.style.visibility = 'visible'
  })
  
  heroPrev.addEventListener('mouseleave', () => {
    prevButton.style.visibility = 'hidden'
  })
  
  heroNext.addEventListener('mouseenter', () => {
    nextButton.style.visibility = 'visible'
  })
  
  heroNext.addEventListener('mouseleave', () => {
    nextButton.style.visibility = 'hidden'
  })
}

