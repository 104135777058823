/* Open and close search bar */
const openFormBtn = document.getElementById('js--open-btn')
const searchInput = document.getElementById('js--search-input')
const menuList = document.querySelector('.menu-menu-1-container')

let inputValue = ""
searchInput.addEventListener('change', () => { inputValue = searchInput.value })

openFormBtn.addEventListener('click', (event) => {
  if (!inputValue) {
    event.preventDefault()
    searchInput.classList.toggle('hidden')
    menuList.classList.toggle('hidden')
  }
})


/* Mobile menu */
const header = document.querySelector('header')
const hamburger = document.querySelector(".hamburger")
const navMenu = document.querySelector(".header-menu")
const navLink = document.querySelectorAll(".menu-item");

function mobileMenu() {
  hamburger.classList.toggle("active")
  navMenu.classList.toggle("active")
  closeOutsideClick()
}

function closeOutsideClick() {
  const isMenuOpen = document.querySelector(".header-menu").classList.contains('active')
  if (isMenuOpen) {
    window.addEventListener('click', function(e) {
      if (!navMenu.contains(e.target) && !header.contains(e.target)) {
        closeMenu();
      }
    })
  }
}

function closeMenu() {
  hamburger.classList.remove("active")
  navMenu.classList.remove("active")
}

hamburger.addEventListener("click", mobileMenu)
navLink.forEach(link => link.addEventListener("click", closeMenu))
