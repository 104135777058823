const readMoreBtn = document.getElementById('readmore')
const resumeText = document.querySelector('.about-content__resume')
const fullText = document.querySelector('.about-content__text')

if(readMoreBtn) {
  readMoreBtn.addEventListener('click', () => {
    console.log('cliquei')
    resumeText.style.display = 'none'
    fullText.style.display = 'block'
    readMoreBtn.style.display = 'none'
  })
}