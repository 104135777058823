import $ from 'jquery';
import './scss/style.scss';
import './js/components/header';
import './js/components/hero';
import './js/components/about';
import './js/components/papers';
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init();

// Carousels
$(function() {
  $('.hero-carousel').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    variableWidth: true,
    prevArrow: $('.prev-hero'),
    nextArrow: $('.next-hero'),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          adaptiveHeight: true,
        }
      },
    ]
  })

  $('.carousel-statements').slick({
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    prevArrow: $('.prev-statements'),
    nextArrow: $('.next-statements'),
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.carousel-resources').slick({
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.hero-carousel').show();
  $('.carousel-statements').show();
  $('.carousel-resources').show();
  $('.loading').hide();
});

const singlePodcastBtn = document.querySelector('#single-copy')
if (singlePodcastBtn) {
  singlePodcastBtn.addEventListener('click', () => {
    const pdfLink = document.querySelector('#single-link')
    let inputc = document.body.appendChild(document.createElement("input"));
    inputc.value = pdfLink.getAttribute('href');
    inputc.focus({
      preventScroll: true
    });
    inputc.select();
    document.execCommand('copy');
    inputc.parentNode.removeChild(inputc);
  })
}

const podcastArr = document.querySelectorAll('.single-tab')
for (let i = 0; i < podcastArr.length; i++) {
  const readmoreBtn = document.getElementById(`readmore-${i}`)
  const readlessBtn = document.getElementById(`readless-${i}`)
  const textPodcast = document.getElementById(`text-${i}`)
  readmoreBtn.addEventListener('click', () => {
    readmoreBtn.classList.add('hidden')
    textPodcast.classList.remove('hidden')
    readlessBtn.classList.remove('hidden')
  })
  readlessBtn.addEventListener('click', () => {
    readmoreBtn.classList.remove('hidden')
    textPodcast.classList.add('hidden')
    readlessBtn.classList.add('hidden')
  })
}

const podcastsToShow = 3

const allPodcasts = document.querySelectorAll('.single-tab')
const firstPodcasts = Array.from(allPodcasts).slice(0, podcastsToShow)
function showFirstPodcasts() {
  firstPodcasts.forEach(item => {
    item.classList.remove('hidden')
  })
}
showFirstPodcasts()

const loadMoreBtn = document.getElementById('loadmore')
const showLessBtn = document.getElementById('showless')
if (allPodcasts.length >= podcastsToShow) loadMoreBtn.classList.remove('hidden')

loadMoreBtn.addEventListener('click', () => {
  let shownPodcasts = 0
  allPodcasts.forEach((item) => {
    if (!item.classList.contains('hidden')) shownPodcasts += 1
  })
  const morePodcasts = Array.from(allPodcasts).slice(shownPodcasts, shownPodcasts + podcastsToShow)
  morePodcasts.forEach(item => {
    item.classList.remove('hidden')
  })
  if (morePodcasts.length < podcastsToShow) {
    loadMoreBtn.classList.add('hidden')
    showLessBtn.classList.remove('hidden')
  }
})

showLessBtn.addEventListener('click', () => {
  const lastPodcasts = Array.from(allPodcasts).slice(podcastsToShow, allPodcasts.length)
  lastPodcasts.forEach(item => {
    item.classList.add('hidden')
  })

  loadMoreBtn.classList.remove('hidden')
  showLessBtn.classList.add('hidden')

  document.getElementById('podcasts').scrollIntoView();
})

const podcastsUrls = document.querySelectorAll('.podcast-link')
const podcastCopyLinkBtn = document.querySelectorAll('.podcast-copy')

function copyUrlToClipboard(i) {
  let inputc = document.body.appendChild(document.createElement("input"));
  inputc.value = podcastsUrls[i].getAttribute('href');
  inputc.focus({
    preventScroll: true
  });
  inputc.select();
  document.execCommand('copy');
  inputc.parentNode.removeChild(inputc);
}

for (let i = 0; i < allPodcasts.length; i++) {
  if (podcastCopyLinkBtn[i]) {
    podcastCopyLinkBtn[i].addEventListener('click', () => {
      copyUrlToClipboard(i)
    })
  }
}

$(".hero-slides__thumbnail").click(function(){
  const has = $(this).hasClass('isPlay')
  if(has) {
    $(this).removeClass('isPlay')
  } else {
    $(this).addClass('isPlay')
  }
})

var $temp = $("<input>");

$('.clipboard').on('click', function() {
  var $url = $(this).attr('value');
  $("body").append($temp);
  $temp.val($url).select();
  document.execCommand("copy");
  $temp.remove();
  $(this).find(".tooltiptext").text("Copied");
})
